import styled from 'styled-components'
import { Navbar } from 'reactstrap'

import media from 'styles'

import Logo from 'components/Logo'

export default styled(Navbar)`
  && {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;

    .nav-item {
      position: relative;
      margin: 0 5px;

      ${ media.greaterThan('xl')`
        margin: 0 10px;
    ` };
    }

    ${ media.greaterThan('md')`
      padding: 40px 15px 0 5px;
    ` };

    ${ media.lessThan('lg')`
      ${ Logo } {
        width: 190px;
      }
    ` };

    ${ media.greaterThan('xl')`
      padding: 40px 55px 0 55px;
    ` };
  }
`
