import styled from 'styled-components'

export default styled.input`
  width: 100%;
  padding: 0 35px;
  line-height: 55px;
  box-shadow: 0 0 21px #dadada;
  background: #fff;
  border: none;
  border-radius: 25px;
  color: #000;
  font-size: 15px;
  -webkit-appearance: none;

  &::placeholder {
    color: #858585;
  }
`
