import styled from 'styled-components'
import { Container } from 'reactstrap'

import { HeadingMargin } from 'components/Heading'

import Background from 'images/cards_particles.png'

export default styled(Container).attrs({ fluid: true })`
  position: relative;
  padding: 0;
  padding-top: ${ HeadingMargin }px;
  margin-bottom: ${ HeadingMargin }px;

  a:hover {
    text-decoration: none;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 120%;
    top: 0;
    background: url(${ Background }) no-repeat center top;
  }
`
