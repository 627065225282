import styled from 'styled-components'
import media from 'styles'
import { Container } from 'reactstrap'

import Background from 'images/contact_widget_background.png'

export default styled(Container).attrs({ fluid: true })`
  margin-top: 40px;
  padding: 70px 0 65px 0;
  background: url(${ Background }) no-repeat right top;

  ${ media.lessThan('md')`
      background-size: 100%;
  ` };
`
