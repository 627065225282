import styled from 'styled-components'
import { Link } from 'gatsby'

import Button from './index'

export default styled(Button).attrs({ as: Link })`
  display: inline-block;
  &:hover,
  &:focus {
    text-decoration: none;
    color: #fff;
  }
`
