import styled from 'styled-components'

import media from 'styles'

export default styled.h2`
  margin-top: 9px;
  font-size: 42px;
  color: #150b0b;

  span {
    color: #008172;
  }

  ${ media.lessThan('sm')`
    font-size: 30px;
  ` }

  &::after {
    display: block;
    content: '';
    width: 99px;
    height: 2px;
    margin: 29px auto 0 auto;
    background-image: linear-gradient(to right, #007c6d 0%, #30ad97 100%);
  }
`
