import React from 'react'
import { Container, Row } from 'reactstrap'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import * as Routes from 'routes'

import HR from 'components/HR'
import Arrow from 'components/Arrow'

import Wrapper from './Wrapper'
import Slogan from './Slogan'
import Nav, { NavLink } from './Nav'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        logotype: file(relativePath: { eq: "logotype.png" }) {
          childImageSharp {
            fixed(width: 71, height: 48) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Container className="text-center text-md-left">
          <Row>
            <Slogan className="col-12 col-md-4">
              <Img
                fixed={data.logotype.childImageSharp.fixed}
                className="mb-2"
                alt="Karer Media"
              />
              <br />
              <strong>Stwórzmy</strong> razem
              <br />
              coś <strong>niezwykłego</strong>
            </Slogan>
            <Nav className="col-12 col-md">
              <strong>Oferta</strong>
              <NavLink>
                <Link to={Routes.OFFER_WEBSITES}>Strony www</Link>
              </NavLink>
              <NavLink>
                <Link to={Routes.OFFER_MOBILE_APPS}>Aplikacje mobilne</Link>
              </NavLink>
              <NavLink>
                <Link to={Routes.OFFER_MISC_SERVICES}>Inne usługi</Link>
              </NavLink>
            </Nav>
            <Nav className="col-12 col-md">
              <strong>Karer Media</strong>
              <NavLink>
                <Link to={Routes.PORTFOLIO}>Realizacje</Link>
              </NavLink>
              <NavLink>
                <Link to={Routes.CONTACT}>Współpraca</Link>
              </NavLink>
              <NavLink>
                <Link to={Routes.CONTACT}>Kontakt</Link>
              </NavLink>
            </Nav>
            <Nav className="col-12 col-md">
              <strong>Dokumenty</strong>
              <NavLink>
                <a href="/pdf/Polityka%20prywatnosci.pdf">
                  Polityka prywatności
                </a>
              </NavLink>
              <NavLink>
                <a href="/pdf/Pliki%20cookies.pdf">Ciasteczka</a>
              </NavLink>
              <NavLink>
                <a href="/sitemap.xml">Mapa strony</a>
              </NavLink>
            </Nav>
            <Nav className="col-12 col-md-auto ml-md-autor">
              <strong>Social media</strong>
              <NavLink>
                <a href="https://www.facebook.com/karermedia" target="_blank">
                  Facebook
                </a>
              </NavLink>
              <NavLink>
                <a
                  href="https://pl.linkedin.com/company/karer-media"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </NavLink>
              <br />
            </Nav>
          </Row>
          <HR />
          <Row>
            <div className="col-12 col-lg-auto">
              Copyright &copy; 2017-{new Date().getFullYear()} Karer Media. All
              rights reserved.
            </div>
            <div className="col-12 col-lg-auto ml-lg-auto">
              <a href="#" className="white">
                wróć do góry &nbsp;
                <Arrow up />
              </a>
            </div>
          </Row>
        </Container>
      </Wrapper>
    )}
  />
)

export default Footer
