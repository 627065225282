import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Wrapper from './Wrapper'

const Image = ({ variant }) => (
  <StaticQuery
    query={graphql`
      query {
        primary: file(relativePath: { eq: "background_effects/primary.png" }) {
          childImageSharp {
            fluid(maxWidth: 1496) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        secondary: file(
          relativePath: { eq: "background_effects/secondary.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1782) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper variant={variant}>
        <Img fluid={data[variant].childImageSharp.fluid} alt="Tło" />
      </Wrapper>
    )}
  />
)

export default Image
