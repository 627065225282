import styled from 'styled-components'

import Background from 'images/card-icon.svg'

export const IconWidth = '141px'
export const IconHeight = '126px'

export default styled.div`
  width: ${ IconWidth };
  height: ${ IconHeight };
  background: url(${ Background }) no-repeat center;
  display: flex;
  justify-content: center;
  align-items: center;
`
