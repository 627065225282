import styled, { css } from 'styled-components'

import media from 'styles'

import Primary from 'images/background/primary.png'
import PrimaryAddon from 'images/background/primary_addon.png'
import Secondary from 'images/background/secondary.png'

const dimensions = {
  primary: {
    height: 1200
  },
  secondary: {
    height: 800
  }
}

const Addon = css`
  &::after {
    content: '';
    background: url(${ PrimaryAddon }) no-repeat right top;
  }
`

const Wrapper = styled.div`
  background: url(${ props =>
    props.variant === 'secondary' ? Secondary : Primary }) no-repeat right top;


  ${ props => props.withAddon && Addon }

  &, &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${ props => dimensions[props.variant].height }px;
    z-index: -2;

    ${ media.lessThan('xxl')`
      background-size: calc(100% - 400px);
    ` }

    ${ media.lessThan('lg')`
      background-size: calc(100% - 200px);
    ` }

    ${ media.lessThan('md')`
      background-size: calc(100% - 225px);
    ` };
  }
`

export default Wrapper
