import styled from 'styled-components'

import media from 'styles'

export const MaxWidth = '544px'

export default styled.h1`
  max-width: ${ MaxWidth };
  font-size: 42px;

  span {
    color: #008172;
  }

  ${ media.lessThan('sm')`
    font-size: 30px;
  ` }
`
