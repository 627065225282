import styled from 'styled-components'
import { Container } from 'reactstrap'

import media from 'styles'

import { HeadingMargin } from 'components/Heading'
import H2 from 'components/H2'

import BackgroundPrimary from 'images/steps/background_primary.png'
import BackgroundSecondary from 'images/steps/background_secondary.png'
import Particles from 'images/steps/particles.png'

export default styled(Container).attrs({ fluid: true })`
  position: relative;
  margin-top: 40px;
  margin-bottom: ${ HeadingMargin }px;
  padding: 100px 0 150px 0;
  background: ${ props => (props.secondary ? '#0c2647' : '#091c35') }
    url(${ props => (props.secondary ? BackgroundSecondary : BackgroundPrimary) })
    no-repeat center center;

  text-align: center;
  font-size: 19px;
  color: #dfe7f1;
  z-index: -1;

  ${ H2 } {
    color: #fff;
  }

  &::before {
    content: '';
    display: block;
    top: -100px;
    position: absolute;
    width: 100%;
    height: calc(100% + 100px);
    background: url(${ Particles }) no-repeat center top;
  }
`

export const List = styled.ul`
  width: 100%;
  margin-top: 80px;
  display: grid;

  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 40px;

  ${ media.lessThan('sm')`
    padding: 0 15px;
  ` }

  ${ media.greaterThan('xl')`
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 60px;
  ` }
`
