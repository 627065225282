import React from 'react'

import Wrapper from './Wrapper'
import Animation from './Animation'

const Left = () => (
  <Wrapper>
    <Animation left>&lt;</Animation>
  </Wrapper>
)

export default Left
