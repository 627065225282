import styled, { css } from 'styled-components'

import H3 from 'components/H3'

import Icon, { IconWidth, IconHeight } from './Icon'

export default styled.div`
  position: relative;
  padding: 45px 36px 85px 36px;
  height: 100%;
  color: #3f3f3f;
  background: #fff;
  box-shadow: 5px 9px 24px 0px rgba(228, 228, 228, 1);
  text-align: center;
  border-radius: 32px;
  transition: transform 0.2s;

  ${ props =>
    props.noIcon &&
    css`
      ${ Icon } {
        min-width: ${ IconWidth };
        min-height: ${ IconHeight };
        width: auto;
        height: auto;
      }

      ${ H3 } {
        color: #008172;
      }
    ` }

  &:hover {
    transform: scale(0.98);
  }
`
