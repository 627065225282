import styled from 'styled-components'

export default styled.a`
  color: #016558;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #000;
    text-decoration: none;
  }
`
