import styled, { keyframes } from 'styled-components'

import media from 'styles'

const dimensions = {
  primary: { width: 1496, top: 50 },
  secondary: { width: 1782, top: 300 }
}

const enter = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20%) translateY(15%);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  z-index: -1;
  animation: 1.4s ${ enter };

  ${ media.lessThan('xxl')`
  width: 90%;
  ` };

  ${ media.lessThan('sm')`
  width: 100%;
  ` };

  ${ media.greaterThan('xxl')`
    top: ${ props => dimensions[props.variant].top }px;
    width: ${ props => dimensions[props.variant].width }px;
    max-width: 100%;
  ` };
`

export default Wrapper
