import styled from 'styled-components'

import media from 'styles'

export default styled.ul`
  display: flex;
  justify-content: center;

  ${ media.lessThan('md')`
    margin-top:5px;

    strong {
      display: none;
    }
  ` };

  ${ media.greaterThan('md')`
    flex-direction: column;
    align-self: flex-end;
  ` };
`

export const NavLink = styled.li`
  &,
  a {
    color: #1c1c1c;
  }

  ${ media.lessThan('md')`
    font-size: 14px;

    &:not(:last-child)::after {
      content: '|';
      display: inline-block;
      margin: 0 5px;
      color: rgba(0, 0, 0, 0.4);
    }
  ` };
`
