import React from 'react'

import Wrapper from './Wrapper'
import Animation from './Animation'

const Right = () => (
  <Wrapper>
    <Animation right>&gt;</Animation>
  </Wrapper>
)

export default Right
