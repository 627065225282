import styled, { css } from 'styled-components'
import { NavLink } from 'reactstrap'

import media from 'styles'

export const ActiveStyle = css`
  font-weight: 500;
  background: #ffffff;
  color: #016558;
`

export const BorderRadius = 22

export default styled(NavLink)`
  display: inline-block;
  padding: 8px 10px !important;
  border-radius: ${ BorderRadius }px;
  transition: background-color 0.2s;

  ${ media.greaterThan('xl')`
    padding: 8px 25px !important;
  ` };

  ${ media.lessThan('md')`
    background: none !important;
    color: #000 !important;
  ` };

  &.active,
  &:focus,
  &:hover {
    ${ ActiveStyle }
  }

  &::after {
    display: block;
    content: attr(title);
    font-weight: 500;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`
