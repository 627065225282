import styled from 'styled-components'

import media from 'styles'

import NavLink, { ActiveStyle, BorderRadius } from 'components/NavLink'

const Width = 250

export const Container = styled.li`
  position: relative;

  ${ media.lessThan('md')`
    text-align: center;
  ` };

  ${ NavLink } {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: none;
  }

  &:hover,
  &:focus {
    ${ NavLink } {
      ${ ActiveStyle };

      ${ media.greaterThan('md')`
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: ${ Width }px;
          height: 40px;
          background: #fff;
          z-index: -1;

          border-top-left-radius: ${ Width }px;
          border-top-right-radius: ${ Width }px;
        }
    ` };
    }

    > ul {
      display: block;
      visibility: visible;
      opacity: 1;
      transform: rotateX(0);
    }
  }
`

export const Menu = styled.ul`
  display: none;
  visibility: hidden;
  padding: 16px 32px;
  opacity: 0;

  ${ media.greaterThan('md')`
    background: #fff;
  ` };

  border-radius: ${ BorderRadius }px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  list-style-type: none;
  line-height: 2;
  z-index: 100;
  transition: opacity 0.25s, transform 0.25s;

  ${ media.greaterThan('md')`
    display: block;
    position: absolute;
    left: 0;
    width: ${ Width }px;
    transform: rotateX(-15deg);
    transform-origin: 50% -50px;
  ` };

  li a {
    display: block;
    color: #150b0b;
    transition: transform 0.3s;

    &:hover {
      color: #016558;
      text-decoration: none;
      transform: translateX(5px);
    }
  }
`
