import { generateMedia } from 'styled-media-query'

import './layout.scss'

export default generateMedia({
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1280px',
  xxl: '1690px',
  wide: '2559px'
})
