import styled from 'styled-components'

import media from 'styles'

import Logo from 'images/logo.svg'

export default styled.img.attrs({ src: Logo, alt: 'Karer Media' })`
  height: 43px;

  ${ media.greaterThan('md')`
    height: 48px;
  ` };
`
