import styled from 'styled-components'

import media from 'styles'

import H1, { MaxWidth as H1MaxWidth } from 'components/H1'

const xxlPaddings = {
  home: '0 300px 0 150px',
  websites: '0 120px 0 150px',
  mobileApps: '0 240px 0 150px',
  miscServices: '0 380px 0 150px'
}

export default styled.div.attrs({ className: 'col-12' })`
  margin-top: 50px;
  font-size: 18px;

  ${ media.lessThan('wide')`
    .container{
      max-width:none;
      padding: 0;
      margin: 0;
    }
  ` }

  ${ media.greaterThan('xl')`
    margin-top: 130px;
  ` }

  ${ media.lessThan('xxl')`
    padding: 0 100px 0 90px;
  ` }

  ${ media.lessThan('xl')`
   padding: 0 30px;
    ${ H1 } {
      max-width: none;
    }
  ` }

  ${ media.greaterThan('xl')`
      padding: 0 100px 0 90px;
      ${ H1 }{
        max-width: ${ H1MaxWidth };
      }
  ` }

  ${ media.greaterThan('xxl')`
    padding: ${ props => xxlPaddings[props.image] };
    margin-top: 258px;
  ` }
`
