import React from 'react'
import PropTypes from 'prop-types'

import Background from 'components/Background'
import BackgroundEffects from 'components/BackgroundEffects'
import Navigation from 'components/Navigation'
import Header from 'components/Header'
import Footer from 'components/Footer'

const Layout = ({ variant, particlesVariant, withAddon, header, children }) => (
  <div>
    <Background variant={variant} withAddon={withAddon} />
    <BackgroundEffects variant={particlesVariant || variant} />
    <Navigation />
    {header && <Header {...header}>{header.content}</Header>}
    {children}
    <Footer />
  </div>
)

Layout.propTypes = {
  variant: PropTypes.string,
  particlesVariant: PropTypes.string,
  withAddon: PropTypes.bool,
  header: PropTypes.shape({}),
  children: PropTypes.node.isRequired
}

Layout.defaultProps = {
  variant: 'primary',
  particlesVariant: null,
  withAddon: false
}

export default Layout
