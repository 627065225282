import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import { Collapse, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap'

import * as Routes from 'routes'

import Logo from 'components/Logo'
import NavLink from 'components/NavLink'
import {
  Container as DropdownContainer,
  Menu as DropdownMenu
} from 'components/NavigationDropdown'
import Arrow from 'components/Arrow'

import Navbar from './Navbar'
// import Language from './Language'

class Navigation extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  }

  render () {
    const { isOpen } = this.state

    return (
      <Navbar light expand="md">
        <NavbarBrand tag={Link} to={Routes.HOME}>
          <Logo />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto align-items-center" navbar>
            <NavItem>
              <NavLink
                tag={Link}
                to={Routes.HOME}
                title="Home"
                activeClassName="active"
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="#o-nas"
                title="O nas"
                activeClassName="active"
                onClick={e => {
                  const element = document.getElementById('o-nas')

                  if (element) {
                    e.preventDefault()

                    element.scrollIntoView({ behavior: 'smooth' })
                  }
                }}
              >
                O nas
              </NavLink>
            </NavItem>
            <DropdownContainer>
              <NavLink href="#" title="Oferta >">
                Oferta <Arrow />
              </NavLink>
              <DropdownMenu>
                <li>
                  <Link to={Routes.OFFER_WEBSITES}>Strony WWW</Link>
                </li>
                <li>
                  <Link to={Routes.OFFER_MOBILE_APPS}>Aplikacje mobilne</Link>
                </li>
                <li>
                  <Link to={Routes.OFFER_MISC_SERVICES}>Inne usługi</Link>
                </li>
              </DropdownMenu>
            </DropdownContainer>
            <NavItem>
              <NavLink
                tag={Link}
                to={Routes.PORTFOLIO}
                title="Portfolio"
                activeClassName="active"
              >
                Portfolio
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to={Routes.CONTACT}
                title="Kontakt z nami"
                activeClassName="active"
              >
                Kontakt z nami
              </NavLink>
            </NavItem>
            {/* <Language /> */}
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

export default Navigation
