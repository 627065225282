import React from 'react'
import { Row } from 'reactstrap'

import * as Routes from 'routes'

import * as CompanyMark from 'components/CompanyMark'
import Heading from 'components/Heading'
import H2 from 'components/H2'
import H4 from 'components/H4'
import Input from 'components/Input'
import Textarea from 'components/Textarea'
import Button from 'components/Button'

import iconLocation from 'images/icons/location.svg'
import iconMail from 'images/icons/mail.svg'

import Wrapper from './Wrapper'
import Information from './Information'
import Icon from './Icon'

const Contact = ({ title, subtitle }) => (
  <Wrapper
    action={Routes.CONTACT_SUCCESS}
    name="kontakt"
    method="POST"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
  >
    <input type="hidden" name="form-name" value="kontakt" />
    <input type="hidden" name="bot-field" />
    <Row>
      <Heading noBackground>
        <H4>
          <CompanyMark.Left /> {subtitle} <CompanyMark.Right />
        </H4>
        <H2>{title}</H2>
      </Heading>
    </Row>
    <Row className="mt-5 mb-5 text-center">
      <Information className="mb-4 mb-md-0">
        <Icon src={iconLocation} alt="Miejsce" />
        <div className="text-center text-md-left d-inline-block">
          Wrocław &amp;
          <br />
          Zielona Góra
        </div>
      </Information>
      <Information>
        <Icon src={iconMail} alt="E-mail" /> kontakt@karermedia.pl
      </Information>
    </Row>
    <Row>
      <div className="col-12 col-md mb-4 mb-md-0">
        <Input
          type="text"
          name="name"
          placeholder="imię / firma"
          autocomplete="off"
          required
        />
      </div>
      <div className="col-12 col-md">
        <Input type="email" name="email" placeholder="adres e-mail" required />
      </div>
    </Row>
    <Row className="mt-4 pt-2">
      <div className="col-12">
        <Textarea
          name="message"
          placeholder="treść twojego zapytania..."
          rows="5"
          autocomplete="off"
          required
        />
      </div>
    </Row>
    <Row>
      <Button variant="primary" className="mx-auto mt-4" type="submit">
        Wyślij wiadomość
      </Button>
    </Row>
  </Wrapper>
)

export default Contact
