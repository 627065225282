import styled from 'styled-components'

import media from 'styles'

import H4 from 'components/H4'

export default styled(H4)`
  margin-bottom: 32px;

  &::before {
    content: '';
    display: inline-block;
    width: 69px;
    height: 2px;
    background: #30ad97;
    vertical-align: middle;
    margin-right: 16px;

    ${ media.lessThan('sm')`
      display: block;
      margin: 0 auto 20px auto;
    ` }
  }
`
