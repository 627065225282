import styled from 'styled-components'

import A from 'components/A'

export default styled(A).attrs({ as: 'div' })`
  position: absolute;
  display: block;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 30px;
  border: 2px solid #000;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-image: linear-gradient(to right, #007c6d 0%, #30ad97 100%);
  border-image-slice: 1;
  font-size: 13px;
  font-weight: 600;

  span {
    color: #150b0b;
  }
`
