import styled from 'styled-components'

import media from 'styles'

import H2 from 'components/H2'
import H4 from 'components/H4'
import CompanyMark from 'components/CompanyMark/Wrapper'

import Background from 'images/heading_background.png'

export const HeadingMargin = 70

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: ${ props => !props.noBackground && '212px' };
  background: ${ props =>
    !props.noBackground && `url(${ Background }) no-repeat center` };

  justify-content: ${ props => (props.top ? 'flex-start' : 'center') };
  align-items: ${ props => !props.left && 'center' };
  ${ props => !props.left && 'text-align: center;' }

  color: ${ props => props.light && '#ffffff' };

  ${ H4 } {
    color: ${ props => props.light && '#829bbb' };
  }

  ${ H2 }::after {
    ${ media.greaterThan('xl')`
      margin-left: ${ props => props.left && 0 };
      margin-right: ${ props => props.left && 0 };
    ` }

    background-image: ${ props =>
    props.secondary && 'linear-gradient(to left, #fdad05 0%, #ff8a00 100%)' };
  }

  ${ CompanyMark } {
    color: ${ props => props.secondary && '#ff8b00' };
  }
`
