import React from 'react'
import { Container, Row } from 'reactstrap'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import H1 from 'components/H1'

import Wrapper from './Wrapper'
import Subtitle from './Subtitle'
import ImageWrapper from './ImageWrapper'

const Header = ({ children, title, subtitle, image }) => (
  <Container fluid tag="header">
    <Row>
      <Wrapper image={image}>
        <Container>
          <Row>
            <div className="col-12 col-xl text-center text-xl-left">
              <Subtitle>{subtitle}</Subtitle>
              <H1>{title}</H1>
              {children}
            </div>
            <ImageWrapper
              image={image}
              className="col-12 col-xl-auto mx-auto mx-xl-0 ml-xl-auto"
            >
              <StaticQuery
                query={graphql`
                  query {
                    home: file(relativePath: { eq: "header/home.png" }) {
                      childImageSharp {
                        fluid(maxWidth: 659, maxHeight: 679) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                    websites: file(
                      relativePath: { eq: "header/websites.png" }
                    ) {
                      childImageSharp {
                        fluid(maxWidth: 873, maxHeight: 506) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                    mobileApps: file(
                      relativePath: { eq: "header/mobile-apps.png" }
                    ) {
                      childImageSharp {
                        fluid(maxWidth: 733, maxHeight: 588) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                    miscServices: file(
                      relativePath: { eq: "header/misc-services.png" }
                    ) {
                      childImageSharp {
                        fluid(maxWidth: 560, maxHeight: 521) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <Img fluid={data[image].childImageSharp.fluid} alt="Usługa" />
                )}
              />
            </ImageWrapper>
          </Row>
        </Container>
      </Wrapper>
    </Row>
  </Container>
)

export default Header
