import styled from 'styled-components'

import media from 'styles'

import Heading from 'components/Heading'

export default styled.main`
  > ${ Heading }:nth-of-type(1) {
    margin-top: 30px;

    ${ media.greaterThan('xl')`
      margin-top: 110px;
  ` }
  }
`
