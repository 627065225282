import styled from 'styled-components'

const variants = {
  primary: 'linear-gradient(to right, #007c6d 0%, #30ad97 50%, #30ad97 100%)',
  secondary: 'linear-gradient(to right, #ff8a00 0%, #fdad05 50%, #fdad05 100%)'
}

export default styled.button`
  padding: 0 16px;
  min-width: 230px;
  line-height: 62px;
  border: none;
  border-radius: 30px;
  background: ${ props => variants[props.variant] };
  background-size: 200% auto;
  text-shadow: 0.5px 0.9px 3px rgba(0, 0, 0, 0.36);
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: background-position 0.6s;

  &:hover {
    background-position: 100% 0;
  }
`
