import React from 'react'
import Fade from 'react-reveal/Fade'

const Animation = ({ left, right, children }) => (
  <Fade left={left} right={right} duation={1000} delay={500}>
    {children}
  </Fade>
)

export default Animation
