import styled, { css } from 'styled-components'

import media from 'styles'

const dimensions = {
  lessThanXxl: {
    home: {
      marginTop: '-70px',
      width: '520px'
    },
    websites: {
      marginTop: '-90px',
      width: '750px'
    },
    mobileApps: {
      marginTop: '0px',
      width: '530px'
    },
    miscServices: {
      marginTop: '-100px',
      width: '480px'
    }
  },
  lessThanXl: {
    home: {
      marginTop: '30px',
      maxWidth: '60%'
    },
    websites: {
      marginTop: '30px',
      maxWidth: '60%'
    },
    mobileApps: {
      marginTop: '30px',
      maxWidth: '60%'
    },
    miscServices: {
      marginTop: '30px',
      maxWidth: '60%'
    }
  },
  greaterThanXxl: {
    home: {
      marginTop: '-225px',
      width: '659px'
    },
    websites: {
      marginTop: '-130px',
      width: '873px'
    },
    mobileApps: {
      marginTop: '-90px',
      width: '733px'
    },
    miscServices: {
      marginTop: '-150px',
      width: '560px'
    }
  },
  greatherThanWide: {
    home: {
      marginTop: '-210px'
    },
    websites: {
      marginTop: '-80px'
    },
    mobileApps: {
      marginTop: '-80px'
    },
    miscServices: {
      marginTop: '-80px'
    }
  }
}

export default styled.div`
  ${ media.lessThan('xxl')`
    margin-top: ${ props => dimensions.lessThanXxl[props.image].marginTop }
    width: ${ props => dimensions.lessThanXxl[props.image].width };
  ` }

  ${ props =>
    props.image === 'websites' &&
    css`
      @media screen and (max-width: 1480px) {
        margin-top: -20px;
        width: 540px;
      }
    ` }

  ${ media.greaterThan('xxl')`
    margin-top: ${ props => dimensions.greaterThanXxl[props.image].marginTop };
    width: ${ props => dimensions.greaterThanXxl[props.image].width };
  ` }

  ${ media.greaterThan('wide')`
    margin-top: ${ props => dimensions.greatherThanWide[props.image].marginTop };
    width: 659px;
  ` }

  ${ media.lessThan('xl')`
    margin-top: ${ props => dimensions.lessThanXl[props.image].marginTop };
    max-width: ${ props => dimensions.lessThanXl[props.image].maxWidth };
  ` }
`
